<template>
  <section class="content">
    <div class="flex justify-content-center">
      <div class="w-100 card">
        <div class="card-body">
          <div class="row">
            <div class="col-xl-3 col-md-3">
              <div class="card shadow-sm border rounded">
                <div class="card-body">
                  <div class="text-muted mb-1 font-13 text-uppercase flex">
                    Shipper
                  </div>
                  <h4 class="mb-3">
                    <span> {{ allSaldo.nama_shipper }} </span>
                  </h4>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-md-3">
              <div class="card shadow-sm border rounded">
                <div class="card-body">
                  <div class="text-muted mb-1 font-13 text-uppercase flex">
                    Total Saldo
                  </div>
                  <h4 class="mb-3">
                    Rp<span> {{ allSaldo.current }} </span>
                  </h4>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-md-3">
              <div class="card shadow-sm border rounded">
                <div class="card-body">
                  <div class="text-muted mb-1 font-13 text-uppercase flex">
                    Bank Shipper
                  </div>
                  <h4 class="mb-0">
                    <span> {{ !allSaldo.bank ? '-' : allSaldo.bank }} </span>
                  </h4>
                  {{ (!allSaldo.code ? '' : allSaldo.code) }}
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-md-3">
              <div class="card shadow-sm border rounded">
                <div class="card-body">
                  <div class="text-muted mb-1 font-13 text-uppercase flex">
                    Dana Tertahan
                  </div>
                  <h4 class="mb-0">
                    <span> {{ allSaldo.retainedFundStr }} </span>
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table table-hover" ref="tablesaldo">
              <thead>
               <tr>
                  <th>TANGGAL</th>
                  <th>TIPE</th>
                  <th>DESKRIPSI</th>
                  <th>JUMLAH</th>
                  <th>KREDIT</th>
                  <th>SALDO</th>
                  <th>STATUS</th>
                  <th>INVOICE ID</th>
                </tr>
              </thead>
              <tbody></tbody>
            </table>
          </div>
          <div class="p-2"></div>
          <router-link
            :to="{ path: '/saldo/shipper' }"
            class="btn btn-secondary"
          >
            Kembali
          </router-link>
        </div>
      </div>
    </div>
  </section>
  <!-- /.content -->
</template>
<script>
import { authFetch, createTable, formatCurrency } from "@/libs/hxcore";
import $ from "jquery";
export default {
  name: "RincianSaldo",
  data() {
    return {
      //   dateStart: "",
      allSaldo: {},
      errors: [],
      method: "",
    };
  },
  created: function () {
    // this.roles = this.$route.meta.roles;
    // console.log("load initial data", this.$route);
    this.loadSaldo(this.$route.params.id);
  },
  methods: {
    loadSaldo: function (id) {
      authFetch("/payment/saldo/allsaldo/" + id).then((res) => {
        res.json().then((json) => {
          this.allSaldo = json.data;
          this.allSaldo.current = formatCurrency(this.allSaldo.current);
          this.allSaldo.nama_shipper = json.data.nama_shipper;
          this.allSaldo.retainedFundStr = this.allSaldo.retainedFund==0?'-' : 'Rp' + formatCurrency(
            this.allSaldo.retainedFund
          );
        });
      });
    },
    handleClick(e) {},
  },
  mounted() {
    const e = this.$refs;
    const self = this;
    this.tbl = createTable(e.tablesaldo, {
      title: "",
      ajax: "/payment/saldo/list/" + this.$route.params.id,
      roles: this.$route.params.roles,
      selectedRowClass: "",
      serverSide: true,
      frame: false,
      processing: true,
      language: {
        processing: '<i class="fa fa-spinner fa-5x fa-spin" style="font-size:50px;color:black;"></i>'},
      displayLength: 10,
      lengthChange: true,
      sort:false,
      paramData: function (d) {
        d.d1 = self.dateStart;
        d.d2 = self.dateEnd;
      },
      columns: [
        { data: "deposit_date" },
        { data: "trans_type" },
        { data: "note" },
        { 
          data: "debit", 
          class:'text-right',
          render: function(data, type, row, meta) {
            if((!data || data==0) && row.credit > 0)data = -row.credit;
            return formatCurrency(data);
          }
        },
        { 
          data: "credit", 
          visible:false,
          class:'text-right',
          render: function(data, type, row, meta) {
            return formatCurrency(data);
          }
        },
        { data: "saldo" ,
          class:'text-right',
          render: function(data, type, row, meta) {
            return formatCurrency(data);
          }
          },
        { data: "status", render: function (data, type, row, meta) {
            let status = '';
            if(row.active < 1)
            {
                return `<span class="badge badge-danger">DITOLAK</span>`;
            } else {
                if (row.status == 0) {
                    status = `<span class="badge badge-light">REQUEST</span>`;
                } else if (row.status == 1) {
                    status = `<span class="badge badge-success">SUKSES</span>`;
                } else if (row.status == 2)  {
                    status = `<span class="badge badge-info">PROSES</span>`;
                } else if (row.status == 3)  {
                    status = `<span class="badge badge-warning text-light">PENDING</span>`;
                }
            }
            return status;
        } },
        { data: "invoice_code" },
      ],
      filterBy: [2, 7],
      rowCallback: function (row, data) {
        
        if (data.trans_type == 0) {
          if(data.status == 0)$("td:eq(6)", row).html(
              data.invoice_code
          );
          $("td:eq(1)", row).html(
            `<span class="badge badge-info">TOP UP</span>`
          );
        } else if (data.trans_type == 1) {
          if(data.debit > 0)
          $("td:eq(1)", row).html(
            `<span class="badge badge-info">REKONSILIASI</span>`
          );

          else $("td:eq(1)", row).html(
            `<span class="badge badge-danger">REKONSILIASI</span>`
          );
        } else if (data.trans_type == 11) {
          $("td:eq(1)", row).html(
            `<span class="badge badge-danger">WITHDRAW</span>`
          );
          //$("td:eq(3)", row).html(data.debit ? data.debit : -data.credit);
        } else {
          $("td:eq(1)", row).html("");
        }
      },
    });
  },
};
</script>